// IMPORT BASICS
import React, { useState, useEffect, useCallback, memo } from "react";

// IMPORT COMPONENTS
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import TableToPrint from "../components/TableToPrint/TableToPrint.js";
import Snackbar from "@mui/material/Snackbar"; //SNACKBAR
import MuiAlert from "@mui/material/Alert"; //SNACKBAR
import Switch from "@mui/material/Switch"; //SWITCH CHILDREN IN HOUSEHOLD

// IMPORT CUSTOM
import TextField from "../components/TextField/TextField.js";
import Select from "../components/Select/Select.js";
import Button from "../components/Button/Button.js";
import Info from "../components/Info/Info.js";
import MissingField from "../components/MissingField/MissingField.js";

// IMPORT DATA
import {
  option_sex,
  option_target_pro,
  option_target_for,
  option_payrolling_fee,
  option_vacation,
  option_payrolling_type,
  option_canton,
  option_children,
  option_confession,
  option_tarif_internal,
  option_tarif_external,
  required,
  option_children_household,
} from "../data/options.data.js";
import { labels, buttons } from "../data/labels.data.js";
import { settings } from "../data/calculation.data.js"; // das wird in Zukunft angefragt.

// IMPORT SCRIPTS
import { calculation } from "../scripts/calculation.js";
import { getStructure } from "../scripts/getStructure.js";
import { setInitialValues } from "../scripts/setInitialValues.js";
import { format } from "../utils/format.js";
import { handleCalculate } from "../utils/handleCalculate.js";
import { isValidFormat } from "../utils/isValidFormat.js";

// ****************************************
// HOME
// The main layout
// ****************************************
const Home = (props) => {
  // ROUTER
  const navigate = useNavigate();

  // STATES
  const [values, setValues] = useState();
  const [resultvalues, setResultvalues] = useState();
  const [structure, setStructure] = useState(null);
  const [result, setResult] = useState(null);
  const [fees, setFees] = useState({ grade1: 0, grade4: 0, grade9: 0 });
  const [toggle, setToggle] = useState(false);
  // const [lan, setLan] = useState("DE");
  const [lan, setLan] = useState(""); // Start with an empty string, no default language

  const [errorFields, setErrorFields] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Function to detect the language from the URL path
    const detectLanguage = () => {
      const pathParts = window.location.pathname.split("/");
      const languageFromPath = pathParts[1].toUpperCase(); // Convert to uppercase ('en' -> 'EN', 'de' -> 'DE', etc.)

      // If language is found in the path, update state and wpml_current_language
      if (languageFromPath && languageFromPath !== lan) {
        setLan(languageFromPath); // Update the state with the language
        window.wpml_current_language = languageFromPath; // Set WPML language code globally
      }
    };

    // Call detectLanguage on page load
    detectLanguage();

    // Listen for changes in the URL (e.g., if user changes language dynamically)
    window.addEventListener("popstate", detectLanguage);

    return () => {
      window.removeEventListener("popstate", detectLanguage); // Clean up listener on unmount
    };
  }, [lan]); // Only run this effect if 'lan' changes

  // EFFECTS
  // ****************************************
  // Set initial navigation
  // ****************************************
  useEffect(() => {
    let path = "?";
    let index = 0;
    const newValues = setInitialValues();
    for (let key in newValues) {
      if (newValues[key] !== null) {
        path = index > 0 ? path + "&" : path;
        path = path + key + "=" + newValues[key];
        index++;
      }
    }
    window.history.replaceState({ ...window.history.state, as: path, url: path },"",path); // prettier-ignore
    setValues(newValues);
    setLan(newValues.lan);
    setResultvalues(newValues);
    calculate(newValues);
  }, []);

  // FUNCTIONS
  // ****************************************
  // Update Values after changes
  // ****************************************
  const updateValues = (label, value) => {
    const tmpValues = { ...values };
    tmpValues[label] = value;
    if (label === "bir") {
      calculateAgeGroup(value, tmpValues);
    } else {
      setValues(tmpValues);
    }
  };

  // ****************************************
  // Calculate Age Group
  // ****************************************
  const calculateAgeGroup = (bir, newValues) => {
    newValues.bir = bir;
    if (isValidFormat(bir, "date") === "") {
      const dmy = bir.split(".");
      const birthdate = new Date(dmy[2] + "-" + dmy[1] + "-" + dmy[0]);
      // const month_diff = Date.now() - birthdate.getTime();
      // var age_dt = new Date(month_diff);
      // var year = age_dt.getUTCFullYear();
      // var age = Math.abs(year - 1970); // change to 1973 WORKS /// original is 1970

      const currentYear = new Date().getFullYear();
      const birthYear = birthdate.getFullYear();
      let age = currentYear - birthYear; // Directly calculate age as the difference in years

      // Check if the current date is before the birthday in the current year
      if (
        new Date().getMonth() < birthdate.getMonth() ||
        (new Date().getMonth() === birthdate.getMonth() &&
          new Date().getDate() < birthdate.getDate())
      ) {
        age--; // Adjust the age if the birthday hasn't occurred yet this year
      }

      age = age + 1; // Add 1 year to the age

      let agegroup = "0_19";
      if (age >= 19 && age <= 24) agegroup = "20_24";
      if (age >= 25 && age <= 30) agegroup = "25_30";
      if (age >= 31 && age <= 34) agegroup = "31_34";
      if (age >= 35 && age <= 44) agegroup = "35_44";
      if (age >= 45 && age <= 49) agegroup = "45_49";
      if (age >= 50 && age <= 54) agegroup = "50_54";
      if (age >= 55 && age <= 65) agegroup = "55_65";
      if (age >= 65) agegroup = "65_69_mit_BVG";
      newValues.age = agegroup;

      console.log("Calculated Age Group:", agegroup);
    }
    setValues(newValues);
  };

  // ****************************************
  // Show Additional Fields
  // ****************************************
  const handleToggle = () => {
    setToggle(!toggle);
  };

  // ***********************************************
  // set Tax at source values to default value null
  // ***********************************************
  const defaultQuell = (values, required) => {
    for (let i = 0; i < required.quell.length; i++) {
      values[required.quell[i]] = null;
    }
  };

  // ***********************************************
  // Calculate
  // ***********************************************
  const calculate = async (inputValue) => {
    const tmpValues = inputValue ? inputValue : { ...values };
    let text = "";

    // Quellsteuern Raten beziehen
    if (tmpValues.can) {
      const file =
        "https://www.rmgroup.ch/files/tar24/tar24" +
        tmpValues.can.toLowerCase() +
        ".txt";
      text = await fetch(file).then((res) =>
        res.text().then((res) => {
          return res;
        })
      );
    }

    // FUNCTION API Call RM CORE FOR SETTINGS MIT DEM ENTSPRECHENDEN JAHR
    const tmpResult = {
      grade1: await calculation(tmpValues, settings, "grade1", text),
      grade4: await calculation(tmpValues, settings, "grade4", text),
      grade9: await calculation(tmpValues, settings, "grade9", text),
    };

    // TO BE ADDED: API CALL QST

    setResult({ ...tmpResult });

    // Struktur beziehen
    const prov_values = { ...values };
    const tmpStructure = getStructure(tmpResult, tmpValues);
    setStructure(tmpStructure);
  };

  // ***********************************************
  // Handle Change
  // ***********************************************
  const handleChange = () => {
    const newValues = {
      ...values,
      ckt: !values.ckt,
    };
    setValues(newValues);
  };

  // *************************************************
  // Handle intern
  //************************************************ */

  const handleClose = (event, reason) => {
    // if (reason === "clickaway") {
    //   return;
    // }
    setOpen(false);
  };

  // ***********************************************
  // handle children in same household
  // ***********************************************

  const handleChildrenHousehold = () => {
    const newValues = {
      ...values,
      chh: !values.chh,
    };
    setValues(newValues);
  };

  const handleHousehold = () => {
    if (values.tar === "A" && values.chi !== null && parseInt(values.chi) > 0) {
      values.ckh = true;
    } else {
      values.ckh = false;
    }
  };

  // *************************************************
  // FETCH TAR PARAMS
  //************************************************ */
  async function fetchTarParams(values) {
    var tarif_letter = "";
    var nChildren = "";

    if (values.tar === "A" && values.chh === true && values.chi > 0) {
      tarif_letter = "H";
    } else {
      tarif_letter = values.tar;
    }
    if (values.tar === "A" && values.ckh === true && values.chh === false) {
      nChildren = "0";
    } else {
      nChildren = values.chi;
    }

    const data = {
      secret: "ObNaSMZiSu5F7P576utzR",
      values: {
        sex: values.sex,
        birthday: values.bir,
        Altersgruppe: values.age,
        Zielwert_für: values.tgf,
        Zielwert: values.tgt,
        pro_dauer: values.tgp,
        sollstunden_pw: values.hrw,
        //QST
        kanton: values.can,
        tarif: tarif_letter,
        kinder: nChildren,
        // kinder_haushalt: values.chh,                    Brauchen wir nicht mitzugeben
        konfession: values.con,
        //
        pensum: values.pen,
        ferien: values.vac,
        msp: values.msp,
      },
    };
    var qstMin_qstSatz; // will become irrelevant
    if (values.tar && values.chi && values.can && values.con) {
      const response_mmg = await fetch(
        "https://eo6gsulriyhtunp.m.pipedream.net",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      // const response_RM = await fetch(
      //   "https://api.rmgroup.ch/v1/core/public/finance/taxatsource",
      //   {
      //     method: "POST",
      //     body: JSON.stringify(data),
      //   }
      // );

      // qstMin_qstSatz = await response_RM.json();
      // const tempValues = { ...values };
    }
  }
  // Handle den Pup up when API-INPUTS sent
  // CALCULATIONS

  // Prüfe den Basissatz auf Stundebasis
  const basissatz = {};
  for (let key in result) {
    const item = result[key];
    basissatz[key] =
      (item.basissatz * item.config.multiplier) / item.config.hours_pj;
  }

  // Toggle
  const toggleClass = toggle ? "toggle_show" : "toggle_hidden";

  if (!values || !lan) return null;

  values.npr = 0;

  // addOn payrolling fee:
  if (values.prf === "perc_kundenrate" || values.prf === "perc_bruttolohn") {
    values.pra = "%";
  } else {
    values.pra = "CHF";
  }

  {
    handleHousehold();
  }

  // RENDER
  return (
    <div className="payrollWrapper">
      <div className="payrollContainer">
        <div className="titleContainer">
          <h1>Payrollee Calculator</h1>
          <p>New rates</p>
        </div>
        <div className="stylingLine"> __________</div>
        <div className="compact">
          <div className="zielwert_container">
            <Select
              key="sex"
              name="sex"
              updateValues={updateValues}
              placeholder="Enter Name"
              title={labels[lan].sex}
              options={option_sex[lan]}
              defaultValue={values["sex"] || null}
            />
          </div>
          <div>
            <TextField
              key="birthday"
              name="bir"
              updateValues={updateValues}
              placeholder="TT.MM.JJJJ"
              title={labels[lan].age}
              defaultValue={values["bir"] || null}
            />
            {errorFields.includes("birthday_format") && (
              <div className="error_field">
                <MissingField appearing={labels[lan].birthday_format} />
              </div>
            )}
          </div>
        </div>
        <div className="compact">
          <div className="zielwert_container">
            <Select
              key="target_for"
              name="tgf"
              info={labels[lan].target_for_info}
              updateValues={updateValues}
              options={option_target_for[lan]}
              title={labels[lan].target_for}
              defaultValue={values["tgf"] || null}
            />
          </div>
          <div className="combofield">
            <TextField
              key="target"
              name="tgt"
              updateValues={updateValues}
              title={""}
              addon="CHF"
              defaultValue={values["tgt"] || null}
              type="fixed"
            />
            <Select
              key="target_pro"
              name="tgp"
              updateValues={updateValues}
              placeholder="Enter Name"
              options={option_target_pro[lan]}
              defaultValue={values["tgp"] || null}
              type="noTitle"
            />
          </div>
        </div>
        {errorFields.includes("tgt") && (
          <div className="error_field">
            <MissingField appearing={labels[lan].target_error} />
          </div>
        )}

        <TextField
          key="pensum"
          addon="%"
          name="pen"
          updateValues={updateValues}
          title={labels[lan].pensum}
          defaultValue={values["pen"] || null}
        />
        {errorFields.includes("pen") && (
          <div className="error_field">
            <MissingField appearing={labels[lan].pensum_error} />
          </div>
        )}
        <div className={toggleClass}>
          <div className="sollstunden_container">
            <TextField
              key="stunde_pw"
              addon="h"
              name="hrw"
              info={labels[lan].sollstunden_info}
              updateValues={updateValues}
              title={labels[lan].sollstunden}
              defaultValue={values["hrw"] || null}
            />
            {errorFields.includes("hrw") && (
              <div className="error_field">
                <MissingField appearing={labels[lan].hours_pw_error} />
              </div>
            )}
          </div>
          <Select
            key="vacation"
            name="vac"
            updateValues={updateValues}
            title={labels[lan].ferien}
            info={labels[lan].ferien_info}
            options={option_vacation[lan]}
            defaultValue={values["vac"] || null}
          />
          <TextField
            addon="%"
            key="msp"
            name="msp"
            updateValues={updateValues}
            disabled
            title={labels[lan].msp}
            defaultValue={values["msp"] || 0}
          />
          {errorFields.includes("ferien_anspruch") && (
            <div className="error_field">
              <MissingField appearing={labels[lan].ferien_korrektur} />
            </div>
          )}
          <div className="section">
            <p>Payrolling Fees</p>
            <div>
              <Select
                // addon="Basis"
                key="payrolling_fee"
                name="prf"
                updateValues={updateValues}
                placeholder="Basis"
                title={labels[lan].payrolling_fee}
                options={option_payrolling_fee[lan]}
                defaultValue={values["prf"] || null}
                type="noTitle"
              />
              <div className="compact">
                <TextField
                  addon={values.pra}
                  key="payrolling_fee_grade1"
                  name="prf_grade1"
                  updateValues={updateValues}
                  disabled
                  title={labels[lan].grade1}
                  defaultValue={values["prf_grade1"] || null}
                />
                <TextField
                  addon={values.pra}
                  key="payrolling_fee_grade4"
                  name="prf_grade4"
                  updateValues={updateValues}
                  disabled
                  title={labels[lan].grade4}
                  defaultValue={values["prf_grade4"] || null}
                />
                <TextField
                  addon={values.pra}
                  key="payrolling_fee_grade9"
                  name="prf_grade9"
                  updateValues={updateValues}
                  disabled
                  title={labels[lan].grade9}
                  defaultValue={values["prf_grade9"] || null}
                />
              </div>
            </div>
          </div>
          <div className="section">
            <div className="checkbox-container-quell">
              <p>{labels[lan].quell_titel}</p>

              <Checkbox
                checked={values.ckt || false}
                onClick={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                size={"small"}
                color={"secondary"}
              />

              <div className="quellsteuer_info_button">
                <Info info={labels[lan].hover} />
              </div>
            </div>

            {values.ckt ? (
              <div>
                <div className="compact source">
                  <div className="select_error">
                    <Select
                      key="canton"
                      name="can"
                      updateValues={updateValues}
                      placeholder="Enter Name"
                      title={labels[lan].kanton}
                      options={option_canton[lan]}
                      defaultValue={values["can"] || null}
                    />
                    {errorFields.includes("can") && (
                      <div className="error_field">
                        <MissingField appearing={labels[lan].canton_error} />
                      </div>
                    )}
                  </div>
                  {values.intern ? (
                    <div className="select_error">
                      <Select
                        key="tarif"
                        name="tar"
                        updateValues={updateValues}
                        placeholder="Enter Name"
                        title={labels[lan].tarif}
                        options={option_tarif_internal[lan]}
                        defaultValue={values["tar"] || null}
                      />
                      {errorFields.includes("tar") && (
                        <div className="error_field">
                          <MissingField appearing={labels[lan].tarif_error} />
                        </div>
                      )}
                      {errorFields.includes("error") && (
                        <div className="error_field">
                          <MissingField
                            appearing={labels[lan].tarif_code_error}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="select_error">
                      <Select
                        key="tarif"
                        name="tar"
                        updateValues={updateValues}
                        info={labels[lan].tarif_info}
                        placeholder="Enter Name"
                        title={labels[lan].tarif}
                        options={option_tarif_external[lan]}
                        defaultValue={values["tar"] || null}
                      />
                      {errorFields.includes("tar") && (
                        <div className="error_field">
                          <MissingField appearing={labels[lan].tarif_error} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="compact source">
                  <div className="children_household">
                    <div className="select_error">
                      <Select
                        key="kids"
                        name="chi"
                        updateValues={updateValues}
                        placeholder="Enter Name"
                        title={labels[lan].children}
                        options={option_children[lan]}
                        defaultValue={values["chi"] || null}
                      />
                      {errorFields.includes("chi") && (
                        <div className="error_field">
                          <MissingField
                            appearing={labels[lan].children_error}
                          />
                        </div>
                      )}
                    </div>
                    {/* {handleHousehold()} */}
                    {values.ckh === true && (
                      <div className="household_switch">
                        <div className="switch_label">
                          {labels[lan].children_household}
                        </div>
                        <div className="switch">
                          <Switch
                            defaultChecked={values["chh"]}
                            default
                            color="secondary"
                            label={labels[lan].children_household}
                            onChange={handleChildrenHousehold}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="select_error">
                    <Select
                      key="confession"
                      name="con"
                      updateValues={updateValues}
                      placeholder="Enter Name"
                      title={labels[lan].confession}
                      options={option_confession[lan]}
                      defaultValue={values["con"] || null}
                    />
                    {errorFields.includes("con") && (
                      <div className="error_field">
                        <MissingField
                          appearing={labels[lan].confession_error}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <FormHelperText sx={{ fontSize: 12 }}>
                  {labels[lan].tarif_label}
                </FormHelperText>
              </div>
            ) : (
              <>{defaultQuell(values, required)}</>
            )}
          </div>
        </div>

        <div className="toggle_button" onClick={() => handleToggle()}>
          {toggle ? buttons[lan].weniger : buttons[lan].mehr}
        </div>

        <Button
          execute={() => {
            fetchTarParams(values).then(
              handleCalculate(
                required,
                values,
                navigate,
                calculate,
                setErrorFields,
                setResultvalues,
                setOpen
              )
            );
            // handleOpenAge();
          }}
        >
          <div>{buttons[lan].calculate}</div>
        </Button>
        <Snackbar
          open={open}
          autoHideDuration={3500}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <MuiAlert
            severity="success"
            elevation={6}
            color="info"
            variant="filled"
          >
            {lan === "DE" && (
              <p className="alertText">
                Berechnung wurde erfolgreich durchgeführt!
              </p>
            )}
            {lan === "EN" && (
              <p className="alertText">
                Calculation was performed successfully!
              </p>
            )}
            {lan === "FR" && (
              <p className="alertText">Le calcul a été effectué avec succès!</p>
            )}
          </MuiAlert>
        </Snackbar>
      </div>
      {structure && result ? (
        <TableToPrint
          labels={labels}
          result={result}
          structure={structure}
          option_payrolling_type={option_payrolling_type}
          values={resultvalues}
          language={lan}
          setValues={setValues}
          getStructure={getStructure}
          setStructure={setStructure}
          setResult={setResult}
          fees={fees}
          setFees={setFees}
          calculate={calculate}
        />
      ) : null}
    </div>
  );
};
export default memo(Home);
