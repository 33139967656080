// IMPORT BASICS
import React from "react";

//IMPORT COMPONENTS
import FormHelperText from "@mui/material/FormHelperText";

// ****************************************
// MissingField (Component)
// Displays a Disclaimer for required Fields
// if not selected
// ****************************************
const MissingField = (props) => {
  // Message,
  // PROPS
  const { appearing } = props;
  // in Home message={message}

  //STYLES
  const sx = {
    color: "red",
    fontSize: 12,
    marginTop: -1.5,
    marginBottom: 1.5,
  };

  return (
    <>
      <FormHelperText sx={sx}>{appearing}</FormHelperText>
    </>
  );
};

export default MissingField;
