// IMPORT BASICS
import React, { useState, memo } from "react";

// IMPORT COMPONENTS
import InputBase from "@mui/material/InputBase";

// IMPORT CUSTOM COMPONENTS
import Info from "../Info/Info.js";

// IMPORT STYLES
import styles from "./TextField.module.scss";

// ****************************************
// TEXTFIELD (Component)
// Draws the textfield
// ****************************************
const TextField = (props) => {
  // PROPS
  const { placeholder, title, name, addon, updateValues, defaultValue, type, info } = props;

  // STATES
  const [value, setValue] = useState(defaultValue);

  // FUNCTIONS
  const handleChange = (event) => {
    setValue(event.target.value);
    updateValues(name, event.target.value);
  };

  // OPERATIONS

  const paddingLeft = addon ? "72px" : "auto";
  const selectClass = type ? styles[type] : null;

  // RENDER
  return (
    <div className={styles["container"] + " " + selectClass}>
      <div className={styles["label"]}>
        <label className={styles["title"]}>{title}</label>
        {info && <Info info={info} />}
      </div>
      <div style={{ paddingLeft: paddingLeft }} className={styles["field"]}>
        {addon ? <p className={styles["addon"]}>{addon}</p> : null}
        <InputBase onChange={handleChange} value={value} placeholder={placeholder} />
      </div>
    </div>
  );
};

export default memo(TextField);
