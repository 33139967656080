// IMPORT BASICS
import React, { useState, memo } from "react";

// IMPORT COMPONENTS
import InputBase from "@mui/material/InputBase";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

// IMPORT CUSTOM COMPONENTS
import Info from "../Info/Info.js";

// IMPORT STYLES
import styles from "./Select.module.scss";
import { Margin } from "@mui/icons-material";

// ****************************************
// SELECT (Component)
// Draws the select field
// ****************************************
const Select = (props) => {
  // PROPS
  const {
    placeholder,
    name,
    addclass,
    title,
    options,
    updateValues,
    defaultValue,
    type,
    width,
    info,
  } = props;

  // STATES
  const [value, setValue] = useState(defaultValue || "");

  // FUNCTIONS
  const handleChange = (event) => {
    setValue(event.target.value);
    updateValues(name, event.target.value);
  };

  // MUI OVERRIDE
  const useStyles = makeStyles({
    select: {
      "&:after": {
        // borderBottomColor: "darkred",
        display: "none",
      },
      "& .MuiSvgIcon-root": {
        // color: "darkred",
        display: "none",
      },
    },
  });

  const classes = useStyles();

  const selectClass = type ? styles[type] : null;
  const classWidth = width ? { width: width } : null;
  const divClass = addclass ? styles[addclass] : null;

  // RENDER
  return (
    <div className={styles["container"] + " " + selectClass} style={classWidth}>
      <div className={styles["label"]}>
        <label className={styles["title"]}>{title}</label>
        {info && <Info info={info} />}
      </div>
      <div className={styles["field"] + " " + divClass}>
        <MuiSelect
          className={classes.select}
          style={{ width: "100%" }}
          onChange={handleChange}
          value={value}
          input={<InputBase placeholder={placeholder} />}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                value={option.value}
                sx={[
                  {
                    color: "black",
                    transition: "0.5s",
                  },
                  {
                    "&:hover": {
                      backgroundColor: "#e85412",
                      color: "white",
                    },
                  },

                  {
                    // mbs: 5,
                  },
                ]}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </div>
    </div>
  );
};

export default memo(Select);
