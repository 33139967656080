export function isValidFormat(value, format) {
  if (format === "date") {
    // First check for the pattern
    if (!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value)) return "Wrong Format";

    // Parse the date parts to integers
    var parts = value.split(".");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12)
      return "Wrong Format";

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    if (day > 0 && day <= monthLength[month - 1]) {
      return "";
    } else {
      return "Invalid Date";
    }
  }
  if (format === "number") {
    // First check for the pattern
    if (isNaN(value)) return "Wrong Format";
  }
  if (format === "email") {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "") {
      return "empty";
    } else if (!emailRegex.test(value)) {
      return "format";
    }
    return "";
  }
  if (format === "phone") {
    const phoneRegex =
      /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/;

    if (value === "") {
      return "empty";
    } else if (!phoneRegex.test(value)) {
      return "format";
    }
    return "";
  }
  if (format === "zipcode") {
    if (value === "") {
      return "empty";
    } else if (isNaN(value) || value.length !== 4) {
      return "format";
    }
    return "";
  }
}
