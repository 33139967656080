// IMPORT BASICS
import React from "react";

//IMPORT COMPONENTS
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const TableToPrintEmpty = (props) => {
  const { result } = props;
  return (
    <TableRow className="fee-noBorder">
      <TableCell></TableCell>
      <TableCell></TableCell>
      {result.grade4.bruttolohn * result.grade4.config.multiplier > 86400 ? <TableCell></TableCell> : null}
      {result.grade9.isgav === "nongav" && result.grade9.bruttolohn * result.grade9.config.multiplier > 86400 ? (
        <TableCell></TableCell>
      ) : null}
    </TableRow>
  );
};

export default TableToPrintEmpty;
